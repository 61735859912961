
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'organizers-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
        phone: Yup.string()
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD'))
          .label('Phone'),
        logo: Yup.mixed()
          .required(() => translate('LOGO_IS_REQUIRED_FIELD'))
          .label('Logo'),
      });

      const fireError = () => {
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        Swal.fire({
          text: translate(error[0]),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: translate('tryAgainExcl'),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        });
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const logoFormData = new FormData();
        // eslint-disable-next-line
        logoFormData.append('images', values.logo);

        delete values.logo;
        const organizer = await store.dispatch(
          Actions.CREATE_ORGANIZER,
          values
        );

        if (!organizer?.id) {
          fireError();
        } else {
          const logo = await store.dispatch(Actions.UPLOAD_ORGANIZER_LOGO, {
            data: logoFormData,
            organizerId: organizer.id,
          });

          if (logo) {
            Swal.fire({
              text: translate('SUCCESSFULLY_CREATED_ORGANIZER'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              router.push({ name: 'organizers-listing' });
            });
          } else {
            fireError();
          }
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('organizersCreating'), [
          translate('auctions'),
        ]);
      });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
      };
    },
  });
